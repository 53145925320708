import { CustomerProductList } from "@redotech/redo-model/customer-product-list/customer-product-list-definition";
import { z } from "zod";

export const getCustomerProductList = {
  input: z.null(),
  output: z.object({
    customerProductList: CustomerProductList.optional(),
  }),
};

export type GetCustomerProductListOutput = z.infer<
  typeof getCustomerProductList.output
>;
