import { createCustomerPortalRpcClient } from "@redotech/customer-portal-sdk/rpc/client";
import { RpcClientContext } from "@redotech/redo-customer-portal-shared/contexts/rpc";
import { createContext, useContext, useMemo } from "react";
import { authentication, getWidgetId } from "../api";
import { AuthContext } from "./auth";

/**
 * Holds all callback subscribers to the RPC client errors
 * @example
 *
 * ```
 *  // Get the error subscribers map from context
 *  const rpcClientErrorSubscribers = useContext(RpcClientErrorSubscribersContext);
 *  // Use a useEffect to hook your subscriber into the component lifetime
 *  useEffect(() => {
 *    const cancelErrorSubscriptionToken = Symbol();
 *    rpcClientErrorSubscribers.set(cancelErrorSubscriptionToken,
 *      async (rpcName: keyof CustomerPortalRpcClient, error: unknown) => {
 *        // Handle the error
 *      },
 *    );
 *  // Returns a cleanup function to remove the error subscriber on component unmount
 *  return () => {
 *    rpcClientErrorSubscribers.delete(cancelErrorSubscriptionToken);
 *  };
 * });
 * ```
 */
export const RpcClientErrorSubscribersContext = createContext<
  Map<
    symbol,
    NonNullable<Parameters<typeof createCustomerPortalRpcClient>[0]["onError"]>
  >
>(new Map());

/**
 * Provides an authenticated RPC client to the app.
 * NOTE: Will be undefined until the user is authenticated.
 */
export const RpcClientContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const authContext = useContext(AuthContext);
  const rpcClientErrorSubscribers: Map<
    symbol,
    NonNullable<Parameters<typeof createCustomerPortalRpcClient>[0]["onError"]>
  > = useContext(RpcClientErrorSubscribersContext);

  const rpcClient = useMemo(
    () =>
      createCustomerPortalRpcClient({
        baseURL: new URL(
          `${
            process.env.REDO_CUSTOMER_PORTAL_SERVER_URL ??
            "http://localhost:8002"
          }/rpc`,
        ),
        headers: {
          "X-WIDGET-ID": getWidgetId() as string,
          Authorization: authentication()["Authorization"],
        },
        onError: async (rpcName, error) => {
          await Promise.allSettled(
            Array.from(rpcClientErrorSubscribers.values()).map((subscriber) =>
              subscriber(rpcName, error),
            ),
          );
        },
      }),
    /* eslint-disable react-hooks/exhaustive-deps */
    [authContext],
  );

  return (
    <RpcClientContext.Provider value={rpcClient}>
      {children}
    </RpcClientContext.Provider>
  );
};
