import { CustomerProductListType } from "@redotech/redo-model/customer-product-list/customer-product-list-definition";
import { z } from "zod";

const customerProductListItemSchema = z.object({
  productId: z.string(),
  productUrl: z.string(),
  productTitle: z.string(),
  imageSrcUrl: z.string().optional(),
  variantId: z.string(),
  variantTitle: z.string().optional(),
  priceAmount: z.number(),
  priceCurrencyCode: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

const customerProductListBodySchema = z.object({
  _id: z.string(),
  team: z.string(),
  email: z.string(),
  listType: z.nativeEnum(CustomerProductListType),
  listName: z.string(),
  items: z.array(customerProductListItemSchema),
});

export const getSharedList = {
  input: z.object({
    listId: z.string(),
    token: z.string(),
  }),
  output: z.object({
    sharedList: customerProductListBodySchema.optional(),
  }),
};
