// Local storage keys
export const CUSTOMER_WIDGET_TOKEN_KEY = "redo.widget_auth_token";
export const CONCIERGE_PROMPT_KEY = "redo.assistant_prompt";
export const CONCIERGE_TREATMENT_KEY = "redo.concierge_treatment";
export const ANONYMOUS_SHOPPER_ID_KEY = "redo.anonymous_shopper_id";

// DOM elements
export const CUSTOMER_WIDGET_ELEMENT_ID = "redo-chat-widget";

// Window events
export const OPEN_CUSTOMER_WIDGET_EVENT_KEY = "redo.open_customer_widget";
export const REDO_USER_LOGGED_IN_EVENT_KEY = "redo_user_logged_in";
export const CONCIERGE_APP_BLOCK_MESSAGE_EVENT_KEY =
  "concierge_app_block_message";

interface ConciergePromptEventDetail {
  prompt: string;
}

export class ConciergePromptEvent extends CustomEvent<ConciergePromptEventDetail> {
  constructor(prompt: string) {
    super(CONCIERGE_APP_BLOCK_MESSAGE_EVENT_KEY, { detail: { prompt } });
  }
}

// URL constants
export const REDO_CONCIERGE_HASH = "#redo-concierge";

export const COVERAGE_TREATMENT_ID_QUERY_PARAM = "treatment_id";
export const CONCIERGE_TREATMENT_ID_QUERY_PARAM = "concierge_treatment_id";

// Cart attribute keys
export const SESSION_IDS_CART_ATTRIBUTE_KEY = "redo.sessionIds";
export const CONCIERGE_ATTRIBUTION_CART_ATTRIBUTE_KEY =
  "redo.conciergeAssisted";
