import * as cloneDeep from "lodash/cloneDeep";
import * as isNull from "lodash/isNull";
import * as mergeWith from "lodash/mergeWith";

export const objectEntries = Object.entries as <T>(
  object: T,
) => [keyof T, T[keyof T]][];

export function objectMapValues<A, B>(
  obj: A,
  fn: (value: A[keyof A], key: keyof A) => B,
): { [K in keyof A]: B } {
  const result = <{ [K in keyof A]: B }>{};
  for (const [key, value] of objectEntries(obj)) {
    result[key] = fn(value, key);
  }
  return result;
}

export function deepCopyObject<T extends object>(obj: T): T {
  return cloneDeep(obj);
}

export function deepMergeObjectsOverwriteNull<T extends object>(
  target: T,
  source: T,
): T {
  return mergeWith(target, source, (objValue, srcValue) => {
    return isNull(objValue) ? srcValue : objValue;
  });
}
