import { memo } from "react";
import { Navbar } from "./navbar";
import * as page from "./page.module.css";

export const Page = memo(function Page({
  alwaysShowNavbar = false,
  showBackButton = true,
  children,
}: {
  alwaysShowNavbar?: boolean;
  showBackButton?: boolean;
  children: React.ReactElement;
}) {
  return (
    <div className={page.pageWrapper}>
      <Navbar
        alwaysShow={alwaysShowNavbar}
        className={page.navBar}
        showBackButton={showBackButton}
      />
      {children}
    </div>
  );
});
