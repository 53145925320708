import { z } from "zod";
import { fontWeights } from "../brand-kit";
import { zExt } from "../common/zod-util";
import { FONT_FAMILIES } from "../email-builder";
import { FaqForgeElementType, FaqJustify } from "../forge/faq-forge-types";

const baseFaqRichTextElementSchema = z.object({
  id: z.string(),
  html: z.string(),
  fontFamily: z.enum(FONT_FAMILIES),
  fontWeight: z.enum(fontWeights),
  fontSizePx: z.number(),
  textColor: z.string(),
  linkColor: z.string(),
});
const faqRichTextElementSchema = baseFaqRichTextElementSchema.extend({
  type: z.literal(FaqForgeElementType.RICH_TEXT),
});
const faqH1ElementSchema = baseFaqRichTextElementSchema.extend({
  type: z.literal(FaqForgeElementType.H1),
});
const faqH2ElementSchema = baseFaqRichTextElementSchema.extend({
  type: z.literal(FaqForgeElementType.H2),
});
const faqH3ElementSchema = baseFaqRichTextElementSchema.extend({
  type: z.literal(FaqForgeElementType.H3),
});
const faqImageElementSchema = z.object({
  type: z.literal(FaqForgeElementType.IMAGE),
  url: z.string(),
  width: z.optional(z.number()),
  justify: z.nativeEnum(FaqJustify),
});
const faqVideoElementSchema = z.object({
  type: z.literal(FaqForgeElementType.VIDEO),
  url: z.string(),
  width: z.number(),
  justify: z.nativeEnum(FaqJustify),
});
const faqDividerElementSchema = z.object({
  type: z.literal(FaqForgeElementType.DIVIDER),
});
const faqToggleElementSchema = z.object({
  type: z.literal(FaqForgeElementType.TOGGLE),
  title: z.string(),
  child: z.string(),
});

export const faqElementSchema = z.union([
  faqH1ElementSchema,
  faqH2ElementSchema,
  faqH3ElementSchema,
  faqRichTextElementSchema,
  faqImageElementSchema,
  faqVideoElementSchema,
  faqDividerElementSchema,
  faqToggleElementSchema,
]);

export const FaqCollectionDbParser = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  title: z.string(),
  description: z.string(),
  articles: z.array(zExt.objectId()),
  published: z.boolean(),
});
export const FaqCollectionArrayDbParser = z.array(FaqCollectionDbParser);

export const FaqArticleDbParser = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  title: z.string(),
  faqCollection: zExt.objectId(),
  elements: z.array(faqElementSchema),
  published: z.boolean(),
  lastEditedAt: z.date(),
});
export const FaqArticleArrayDbParser = z.array(FaqArticleDbParser);

export type IFaqCollection = z.infer<typeof FaqCollectionDbParser>;
export type IFaqArticle = z.infer<typeof FaqArticleDbParser>;
