import { z } from "zod";

export const ProductListItem = z.object({
  productId: z.string(),
  productUrl: z.string(),
  productTitle: z.string(),
  imageSrcUrl: z.string().optional(),
  variantId: z.string(),
  variantTitle: z.string().optional(),
  priceAmount: z.number(),
  priceCurrencyCode: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});
export type IProductListItem = z.infer<typeof ProductListItem>;

export type IProductListItemInput = Omit<
  IProductListItem,
  "createdAt" | "updatedAt"
>;

/**
 * Omit createdAt and updatedAt fields from IProductListItem.
 */
export function getProductListItemInputFromProductListItem(
  productListItem: IProductListItem,
): IProductListItemInput {
  const { createdAt, updatedAt, ...productListItemInput } = productListItem;
  return productListItemInput;
}

export enum CustomerProductListType {
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
}
export const CUSTOMER_PRODUCT_LIST_DEFAULT_NAME = "Saved items";

export const CustomerProductList = z.object({
  _id: z.string(),
  team: z.string(),
  email: z.string(),
  listType: z.nativeEnum(CustomerProductListType),
  listName: z.string(),
  items: z.array(ProductListItem),
});
export type ICustomerProductList = z.infer<typeof CustomerProductList>;
