import { RouteObject } from "react-router-dom";

export const marketingRoutes: RouteObject[] = [
  {
    lazy: async () => {
      const { MarketingPageBase } = await import("./marketing-page-base");
      return { Component: MarketingPageBase };
    },
    children: [
      {
        path: "unsubscribe",
        lazy: async () => {
          const { UnsubscribePage } = await import(
            "./unsubscribe/unsubscribe-page"
          );
          return { element: <UnsubscribePage /> };
        },
      },
    ],
  },
];
