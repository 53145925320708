import { ObjectId } from "bson";
import {
  BorderStrokeStyleDefinition,
  BorderStyleDefinition,
  ButtonOptionsOverride,
  Enableable,
  FontFamily,
  FontWeight,
  HexCode,
  InputStyleDefinition,
  Override,
  defaultBrandKit,
  defaultTertiaryButton,
} from "./brand-kit";
import {
  ButtonElement,
  ImageElement,
  InputElement,
  MarketingForgeElementType,
  RichTextElement,
} from "./forge/marketing-signup-forge-types";

export const directions = [
  "top",
  "right",
  "bottom",
  "left",
  "no",
  "background",
] as const;
export type Direction = (typeof directions)[number];

export const layoutTypes = ["popup", "fullscreen"] as const;
export type LayoutType = (typeof layoutTypes)[number];

export const inputTypes = ["email", "phone"] as const;
export type InputType = (typeof inputTypes)[number];

export const captureGoalTypes = ["phone", "email", "both"] as const;
export type CaptureGoalType = (typeof captureGoalTypes)[number];

export const deviceOptions = ["desktop", "mobile", "desktop & mobile"] as const;
export type DeviceOption = (typeof deviceOptions)[number];

export const signupFormSiteTypes = [
  "tracking-page",
  "marketing",
  "customer-account",
] as const;
export type SignupFormSiteType = (typeof signupFormSiteTypes)[number];

export const discountTypes = ["percent", "fixed"] as const;
export type DiscountType = (typeof discountTypes)[number];

export const audienceTypes = ["all visitors", "based on campaign"] as const;
export type AudienceType = (typeof audienceTypes)[number];

export const targetingTypes = [
  "all visitors",
  "exclude existing contacts",
  "only existing contacts",
] as const;
export type TargetingType = (typeof targetingTypes)[number];

export const buttonActions = ["submit-and-advance", "close"] as const;
export type ButtonAction = (typeof buttonActions)[number];

export const teaserVisibility = ["before-displaying", "after-closing"] as const;
export type TeaserVisibility = (typeof teaserVisibility)[number];

export const alignmentHorizontalValues = ["left", "center", "right"] as const;
export type AlignmentHorizontal = (typeof alignmentHorizontalValues)[number];

export const alignmentVerticalValues = ["top", "center", "bottom"] as const;
export type AlignmentVertical = (typeof alignmentVerticalValues)[number];

export interface Alignment2D {
  vertical: AlignmentVertical;
  horizontal: AlignmentHorizontal;
}

export const fontWeightValues: Record<FontWeight, number> = {
  regular: 400,
  semibold: 600,
  bold: 700,
};

export interface SignupForm {
  name: string;
  theme: {
    overrides: {
      buttons: Enableable<ButtonOptionsOverride>;
      inputs: Enableable<Override<InputStyleDefinition>>;
    };
    border: BorderStyleDefinition;
    closeButton: {
      color: HexCode;
      background: Enableable<{ color: HexCode }>;
    };
    overlay: {
      // could be combined as a single hexcode,
      // but native HTML color input (used by our ColorPicker) doesn't support alpha
      color: HexCode;
      opacityPercent: number; // 0-100
    };
  };
  layout: {
    type: LayoutType;
    backgroundColor: HexCode;
    image: {
      style: Direction;
      url: string;
      hideOnMobile: boolean;
    };
  };
  discount: Enableable<{
    type: DiscountType;
    // keep both fields for flexibility
    // and preserving data when switching between types
    amountUsd: number;
    percentage: number; // 0-100
    excludeOnSale: boolean;
  }>;
  behavior: {
    subscriberTags: string[];
    display: {
      timeOnPage: Enableable<{ seconds: number }>;
      exitIntent: Enableable<{}>;
    };
    audience: AudienceType;
    campaignUtms: string[];
    targeting: TargetingType;
    appearsOn: DeviceOption;
    appearsOnSite: SignupFormSiteType;
  };
  teaser: Enableable<Teaser>;
  steps: FormStep[];
}

export interface SignupFormWithAnalytics {
  _id: string | ObjectId;
  team: string | ObjectId;
  form: SignupForm;
  publishedAt: Date | null;
  analytics: {
    views: number; // unique views (one per visitor)
    submissions: number;
  };
}

export interface FormStep {
  elements: FormElement[];
}

export interface Teaser {
  text: string;
  fontFamily: FontFamily;
  fontWeight: FontWeight;
  fontSizePx: number;
  positionHorizontal: AlignmentHorizontal;
  positionVertical: AlignmentVertical;
  backgroundColor: HexCode;
  textColor: HexCode;
  cornerRadiusPx: number;
  borderStroke: BorderStrokeStyleDefinition;
  iconVisible: boolean;
  visibility: TeaserVisibility;
}

export type FormElement =
  | RichTextElement
  | ButtonElement
  | InputElement
  | ImageElement;

const surveyStep: FormStep = {
  elements: [
    {
      id: 1,
      type: MarketingForgeElementType.RICH_TEXT,
      html: '<p class="ql-align-start"><b>Get 10% off</b></p>',
      fontFamily: FontFamily.ARIAL,
      fontWeight: "bold",
      fontSizePx: 32,
      textColor: "#000000",
      linkColor: "#0000ff",
    },
    {
      id: 2,
      type: MarketingForgeElementType.RICH_TEXT,
      html: '<p class="ql-align-start">Join our mailing list today and get a discount on your next order. Interested?</p>',
      fontFamily: FontFamily.ARIAL,
      fontWeight: "regular",
      fontSizePx: 16,
      textColor: "#000000",
      linkColor: "#0000ff",
    },
    {
      type: MarketingForgeElementType.BUTTON,
      hierarchy: "primary",
      text: "Yes, sign me up",
      action: "submit-and-advance",
    },
    {
      type: MarketingForgeElementType.BUTTON,
      hierarchy: "secondary",
      text: "No thanks",
      action: "close",
    },
  ],
};

export const captureStep = (captureGoal: CaptureGoalType): FormStep => {
  const step: FormStep = {
    elements: [
      {
        id: 1,
        type: MarketingForgeElementType.RICH_TEXT,
        html: '<p class="ql-align-start"><b>Get 10% off your first order</b></p>',
        fontFamily: FontFamily.ARIAL,
        fontWeight: "bold",
        fontSizePx: 32,
        textColor: "#000000",
        linkColor: "#0000ff",
      },
      {
        id: 2,
        type: MarketingForgeElementType.RICH_TEXT,
        html: '<p class="ql-align-start">Sign up for special offers and updates</p>',
        fontFamily: FontFamily.ARIAL,
        fontWeight: "regular",
        fontSizePx: 16,
        textColor: "#000000",
        linkColor: "#0000ff",
      },
    ],
  };
  if (captureGoal === "email" || captureGoal === "both") {
    step.elements.push({
      type: MarketingForgeElementType.INPUT,
      inputType: "email",
      placeholderText: "Enter your email address",
    });
  }
  if (captureGoal === "phone" || captureGoal === "both") {
    step.elements.push({
      type: MarketingForgeElementType.INPUT,
      inputType: "phone",
      placeholderText: "Enter your phone number",
    });
  }
  step.elements.push({
    type: MarketingForgeElementType.BUTTON,
    hierarchy: "primary",
    text: "Sign up",
    action: "submit-and-advance",
  });
  return step;
};

const successStep: FormStep = {
  elements: [
    {
      id: 1,
      type: MarketingForgeElementType.RICH_TEXT,
      html: '<p class="ql-align-center">Thank you for subscribing!</p>',
      fontFamily: FontFamily.ARIAL,
      fontWeight: "bold",
      fontSizePx: 32,
      textColor: "#000000",
      linkColor: "#0000ff",
    },
    {
      type: MarketingForgeElementType.BUTTON,
      hierarchy: "primary",
      text: "Close",
      action: "close",
    },
  ],
};

export const getExampleSteps = (
  captureGoal: CaptureGoalType,
  includeSurvey: boolean,
) => {
  const steps: FormStep[] = [];
  if (includeSurvey) {
    steps.push(surveyStep);
  }
  steps.push(captureStep(captureGoal));
  steps.push(successStep);
  return steps;
};

export const getExampleForm = (
  name: string,
  captureGoal: CaptureGoalType,
  includeSurvey: boolean,
): SignupForm => {
  return {
    name,
    theme: {
      overrides: {
        buttons: {
          enabled: false,
          primary: {
            ...defaultBrandKit?.buttons.primary,
            font: {
              fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
              fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
              fontFamily: defaultBrandKit?.font.fontFamily,
            },
          },
          secondary: {
            ...defaultBrandKit?.buttons.secondary,
            font: {
              fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
              fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
              fontFamily: defaultBrandKit?.font.fontFamily,
            },
          },
          tertiary: {
            ...defaultTertiaryButton,
            font: {
              fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
              fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
              fontFamily: defaultBrandKit?.font.fontFamily,
            },
          },
        },
        inputs: {
          ...defaultBrandKit?.inputs,
          enabled: false,
          font: {
            fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
            fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
            fontFamily: defaultBrandKit?.font.fontFamily,
          },
        },
      },
      closeButton: {
        color: "#000000",
        background: { enabled: false, color: "#ffffff" },
      },
      border: {
        cornerRadiusPx: 0,
        stroke: {
          color: "#000000",
          weightPx: 0,
        },
      },
      overlay: {
        color: "#000000",
        opacityPercent: 75,
      },
    },
    layout: {
      type: "popup",
      backgroundColor: "#ffffff",
      image: {
        style: "left",
        url: "",
        hideOnMobile: false,
      },
    },
    discount: {
      enabled: true,
      type: "percent",
      percentage: 10,
      amountUsd: 5,
      excludeOnSale: false,
    },
    behavior: {
      appearsOn: "desktop & mobile",
      appearsOnSite: "marketing",
      subscriberTags: [`${name.toLowerCase().split(" ").join("_")}_redo_popup`],
      display: {
        timeOnPage: { enabled: true, seconds: 10 },
        exitIntent: { enabled: false }, // TODO: support this
      },
      audience: "all visitors",
      campaignUtms: [],
      targeting: "all visitors",
    },
    teaser: {
      enabled: true,
      text: "Get 10% off",
      fontFamily: FontFamily.ARIAL,
      fontWeight: "bold",
      fontSizePx: 16,
      positionHorizontal: "left",
      positionVertical: "bottom",
      backgroundColor: "#000000",
      textColor: "#ffffff",
      cornerRadiusPx: 5,
      borderStroke: {
        color: "#000000",
        weightPx: 0,
      },
      iconVisible: false,
      visibility: "before-displaying",
    },
    steps: getExampleSteps(captureGoal, includeSurvey),
  };
};
