import { getSignupFormSchema } from "@redotech/redo-model/marketing/schemas/get-signup-form-schema";
import { handleMarketingEmailUnsubscribeSchema } from "@redotech/redo-model/marketing/schemas/handle-marketing-email-unsubscribe-schema";
import { submitSignupFormStepSchema } from "@redotech/redo-model/marketing/schemas/submit-signup-form-step-schema";
import { logSignupFormAnalyticsSchema } from "@redotech/redo-model/marketing/schemas/view-signup-form-schema";

export const getSignupForm = getSignupFormSchema;

export const submitSignupFormStep = submitSignupFormStepSchema;

export const logSignupFormAnalytics = logSignupFormAnalyticsSchema;

export const handleMarketingEmailUnsubscribe =
  handleMarketingEmailUnsubscribeSchema;
