import "@redotech/money/currencies"; // Necessary to fix "inferred type not portable" type errors

import {
  AddressSchema,
  CompensationMethodType,
  DraftReturnSchema,
  DraftReturnShipmentSchema,
  InteractiveMapLocationSchema,
  Provision,
  ReturnTotalCalculationsOutputSchema,
  SettlementSchema,
  ShipmentMethodSchema,
} from "@redotech/redo-model/draft-return";
import {
  NewExchangeItemSchema,
  ReturnableItemSchema,
} from "@redotech/redo-model/draft-return-items";
import {
  faqArticleBodySchema,
  faqCollectionBodySchema,
  faqThemeBodySchema,
} from "@redotech/redo-model/faq/faq-body-schema";
import { OrderSchema } from "@redotech/redo-model/order-schema";
import { ReturnZodSchema } from "@redotech/redo-model/return";
import { getWarrantyEligibleProductsSchema } from "@redotech/redo-model/warranties/get-warranty-eligible-products-schema";
import { submitWarrantyRegistrationSchema } from "@redotech/redo-model/warranties/submit-warranty-registration-schema";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const changeAddress = {
  input: z.object({
    draftReturnId: z.string(),
    newOrderAddress: AddressSchema.nullish(),
    returnAddress: AddressSchema.nullish(),
  }),
  output: z.object({
    draftReturn: DraftReturnSchema,
  }),
};

export const editReturnableItem = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    item: ReturnableItemSchema,
  }),
  output: z.object({
    draftReturn: DraftReturnSchema,
    newItemId: z.string(),
  }),
};

export const editPendingItemQuantity = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    pendingItemId: z.string(),
    updatedQuantity: z.number(),
  }),
  output: z.object({
    draftReturn: DraftReturnSchema,
  }),
};

export const getDraftReturn = {
  input: z.object({
    draftReturnId: zExt.objectId(),
  }),
  output: z.object({
    draftReturn: DraftReturnSchema.nullable(),
  }),
};

export const getShippingEstimate = {
  input: z.object({
    draftReturnId: zExt.objectId(),
  }),
  output: z.array(DraftReturnShipmentSchema),
};

export const getCommentSoldRecommendedProducts = {
  input: z.object({
    excludedVariantIds: z.array(z.string()),
    excludedParentProductIds: z.array(z.string()),
    numRecommendations: z.number(),
    strategy: z.any(),
  }),
  output: z.object({
    products: z.array(z.any()), // TODO @jsterner30 Type
  }),
};

export const getStripePublicKey = {
  input: z.object({}).nullish(),
  output: z.object({
    publicKey: z.string(),
  }),
};

export const selectShipmentMethod = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    shipmentMethod: ShipmentMethodSchema,
  }),
  output: z.object({
    draftReturn: DraftReturnSchema,
  }),
};

export const setCompensationMethod = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    selectedMethod: z.nativeEnum(CompensationMethodType),
  }),
  output: z.object({
    draftReturn: DraftReturnSchema,
  }),
};

export const removeReturnItem = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    pendingItemId: z.string(),
  }),
  output: z.object({
    draftReturn: DraftReturnSchema,
  }),
};

export const submitReturn = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    settlement: SettlementSchema.nullish(),
    provisionType: Provision,
    paymentToken: z.string().nullish(),
    customerTimeZone: z.string().nullish(),
  }),
  output: z.object({
    returnId: z.string(),
  }),
};

export const submitReturnNew = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    settlement: SettlementSchema.nullish(),
    provisionType: Provision,
    paymentToken: z.string().nullish(),
  }),
  output: z.object({
    returnId: z.string(),
  }),
};

export const rejectReturnItems = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    returnableItemIds: z.array(z.string()),
    rejectReason: z.string(),
    rejectMessage: z.string(),
    flowPath: z.array(z.number()).readonly().optional(),
    multipleChoiceAnswers: z
      .array(
        z.object({
          step: z.number(),
          answer: z.number(),
        }),
      )
      .readonly()
      .optional(),
  }),
  output: z.object({}),
};

export const addNewItems = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    items: z.array(NewExchangeItemSchema),
    clearExistingItems: z.boolean().optional(),
    cartToken: z.string().nullish(),
  }),
  output: z.object({
    draftReturn: DraftReturnSchema,
  }),
};

export const removeNewItems = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    variantIds: z.array(z.string()),
  }),
  output: z.object({
    draftReturn: DraftReturnSchema,
  }),
};

export const verifyAddress = {
  input: z.object({
    address: AddressSchema,
  }),
  output: z.object({
    verifiedAddress: AddressSchema,
    verifications: z.object({
      delivery: z.object({
        success: z.boolean(),
      }),
    }),
  }),
};

export const calculateReturnTotals = {
  input: z.object({
    draftReturnId: zExt.objectId(),
  }),
  output: z.object({
    calculations: ReturnTotalCalculationsOutputSchema,
  }),
};

export const getLocations = {
  input: z.object({
    draftReturnId: zExt.objectId(),
  }),
  output: z.object({
    locations: z.array(InteractiveMapLocationSchema),
  }),
};

export const getSatisfactionResponse = {
  input: z.object({
    conversationId: z.string(),
  }),
  output: z.object({
    rating: z.number().nullish(),
    comment: z.string().nullish(),
    createdAt: z.date().nullish(),
  }),
};

export const saveSatisfactionResponse = {
  input: z.object({
    channel: z.string(),
    comment: z.string(),
    conversationId: z.string(),
    rating: z.number(),
  }),
  output: z.object({
    success: z.boolean(),
  }),
};

export const getFaq = {
  input: z.object({}).nullish(),
  output: z.object({
    theme: faqThemeBodySchema,
    collections: z.array(faqCollectionBodySchema),
    articles: z.map(z.string(), faqArticleBodySchema),
  }),
};

export const getWarrantyEligibleProducts = getWarrantyEligibleProductsSchema;

export const getWarrantyRegistrationFlow = {
  input: z.object({}).nullish(),
  output: z.object({
    draftWarrantyRegistration: DraftReturnSchema,
  }),
};

export const submitWarrantyRegistration = submitWarrantyRegistrationSchema;

export const getOrderId = {
  input: z.object({ shopifyId: z.string() }),
  output: z.object({ id: zExt.objectId() }),
};

export const toggleSelectReturnItem = {
  input: z.object({
    draftReturnId: z.string(),
    returnItemId: z.string(),
  }),
  output: z.object({
    draftReturn: DraftReturnSchema,
  }),
};

export const setProvisionType = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    provisionType: Provision.nullish(),
  }),
  output: z.object({
    draftReturn: DraftReturnSchema,
  }),
};

export const getReturn = {
  input: z.object({
    returnId: z.string(),
  }),
  output: z.object({
    return: ReturnZodSchema,
  }),
};

export const getOrders = {
  input: z.object({
    orderIds: z.array(zExt.objectId()),
  }),
  output: z.object({
    orders: z.array(OrderSchema),
  }),
};

export const isShopAppDisabled = {
  input: z.object({}).nullish(),
  output: z.object({
    isDisabled: z.boolean(),
  }),
};

export const updatePickupVariant = {
  input: z.object({
    draftReturnId: zExt.objectId(),
    pickupVariant: z.string(),
  }),
  output: z.object({
    draftReturn: DraftReturnSchema,
  }),
};
